import { useState, React } from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn, getUser } from "../services/auth"
import MessageOverlay from './messageOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUserLock } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [state, setState] = useState({
    email: ``,
    password: ``,
  });

  const [submissionError, setSubmissionError] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = event => {
    console.log(`target name = ${event.target.name}`);
    let newState  = state;
    newState[event.target.name] = event.target.value;
    setState(newState);
  }

  const divBackground = 'bg-blue-100 shadow-md rounded px-8 pt-6 pb-8 mt-12 mb-4 flex flex-col';
  const divBackgroundError = 'bg-red-100 shadow-md rounded px-8 pt-6 pb-8 mt-12 mb-4 flex flex-col';
  const submitButtonStyle = 'bg-primary hover:bg-blue-dark text-white font-bold py-2 px-4 rounded';
  const submitButtonStyleDisabled = 'bg-primary hover:bg-blue-dark text-white font-bold py-2 px-4 rounded bg-indigo-300 opacity-50 cursor-not-allowed';

  const closeOverlay = () => {
    //console.log('closeOverlay function!');
    setShowOverlay(false);
    setSubmissionError(null);
    //console.log(`ShowOverlay is ${showOverlay}`);
  }

  const errorOverlay = (
    <MessageOverlay type="error" text={submissionError} close={closeOverlay} />
  )

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!disableSubmit){
      setDisableSubmit(true);
      //console.log(this.state);

      const authUser = await handleLogin(state).catch(err => {
        console.log(err, JSON.parse(err.message), Object.keys(err));
        setSubmissionError(JSON.parse(err.message).message);
        setDisableSubmit(false);
      });
      // let test = handleLogin(this.state);
      console.log('From handlesubmit: --->');
      console.log(authUser);
      if(authUser) {
        navigate(`/app/profile`, { state: authUser});
      } else {
        //setSubmissionError(true);
        setShowOverlay(true);
      }
    }
  }

  if (isLoggedIn()) {
    console.log(`user logged in, data ${getUser()}`);
    //setReturnedData(getUser());
    navigate(`/app/profile`, { state: getUser()});
    return null;
  } else {
    console.log(`user not logged in: ${isLoggedIn()}`);
    return (
        <section className="pt-5 md:pt-10">
          <div className="container mx-auto px-8 flex">
            <div className="text-center lg:text-left lg:w-1/2">
              <h1 className="text-xl lg:text-2xl xl:text-3xl font-bold leading-none">
                Log in
              </h1>


              <form
                method="post"
                onSubmit={event => {
                  handleSubmit(event);
                }}
              >

                <div className={submissionError ? divBackgroundError : divBackground}>
                  {showOverlay && errorOverlay}
                  <div className="mb-4">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                      E-mail
                    </label>
                    <input onChange={handleUpdate} className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" name="email" id="email" type="email" placeholder="john@example.com" autoComplete="email" />
                  </div>
                  <div className="mb-6">
                    <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
                      Password
                    </label>
                    <input onChange={handleUpdate} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"  name="password" id="password" type="password" autoComplete="current-password" placeholder="******************" />
                    <p className="text-red text-xs italic">Please enter your password.</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <button className={ disableSubmit ? submitButtonStyleDisabled : submitButtonStyle} type="submit">
                      <FontAwesomeIcon icon={ disableSubmit ? faSpinner : faUserLock} className={ disableSubmit ? 'animate-spin mr-2' : 'mr-2'} />Sign In
                    </button>
                    <a className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="/">
                      Forgot Password?
                    </a>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default Login
