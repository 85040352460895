import { React, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAfrica, faUserTie, faBuilding, faCreditCard, faFileInvoice, faReceipt, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { isLoggedIn, getUser } from "../services/auth";
import MessageOverlay from '../components/messageOverlay';
import QRCode from "react-qr-code";

const Profile = ( { location } ) => {
  //console.log(MessageOverlay);
  //console.log('MessageOverlay is..');
  const dateOtions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  //console.log(user);

  const [user, setUser] = useState(() => {
    return isLoggedIn() ? getUser() : location.state
  })


  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({subscription: user.subscription.id, user: user._id, token: user.token}),
    mode: 'cors',
    headers:{'content-type': 'application/json'},
    redirect: 'follow',
  };

  const [error, setError] = useState({
    status: false,
    message:''
  });

  const [success, setSuccess] = useState({
    status: false,
    message:''
  });

  const backendURL = `https://${user.subdomain}-admin.punch-in.co.uk`;

  const qrCodeData = {
    baseUrl: `https://${user.subdomain}.punch-in.co.uk`,
    identifier: 'biometricscanner@example.com',
    password: '3zk_Yv33k.Lw',
    timeOut: '25',
  }


  const endSubscription = () => {
    let errorMessage = ''
    fetch('https://api.punch-in.co.uk/end-subscription', fetchOptions)
      .then(res =>{
        console.log(res);
        if(res.status >199 && res.status < 300){
          // Login successful, hide login
          return res.json();
        } else {
          return null;
        }
      })
      .then(response =>{
        /// this is the userDetails data
        if(response) {
          console.log(response);
          setSuccess({
            status: true,
            message: 'Subscription successfully suspended'
          });
          return;
        }
        setError({
          status: true,
          message: 'Your request could not be completed. Please retry or contact support'
          //message: JSON.stringify(response.message.raw.message
        });
    }).catch(err => {
      console.log(err);
      setError({
        status: true,
        message: err
      });
    });
  }

  const closeOverlay = () => {
    if(error.status) {
      setError({
        status: false,
        message: ''
      });
    } else {
        setSuccess({
          status: false,
          message: ''
        });
    }


  }

  const formattedEndDate = new Date(user.subscription.current_period_end).toLocaleDateString('en-UK', dateOtions);
  const isOverdue = (new Date(user.subscription.current_period_end)).getTime() < Date.now() ? true : false;
  const newBilling = new Date(user.subscription.current_period_end);
  newBilling.setDate(newBilling.getDate() + 1)
  const formattedNewBilling = newBilling.toLocaleDateString('en-UK', dateOtions);
  return (
    <div>
      { error.status && (<MessageOverlay type="error" text={JSON.stringify(error.message)} close={closeOverlay} />)}
      { success.status && (<MessageOverlay text={JSON.stringify(success.message)} close={closeOverlay} />)}
      <section className="pt-5 md:pt-10">
        <div className="container mx-auto px-8 flex">
          <div className="text-center lg:text-left lg:w-1/2 rounded-lg shadow">
            <h1 className="p-3 text-xl text-blue-800 lg:text-2xl xl:text-3xl font-bold leading-none">
              <FontAwesomeIcon icon={faUserTie} /> Account details
              </h1>
            <ul  className="ml-4">
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">Name: <strong>{user.firstname} {user.lastname} </strong></li>
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">E-mail: <strong>{user.email}</strong></li>
            </ul>
          </div>
        </div>
      </section>
      <section className="pt-5 md:pt-10">
        <div className="container mx-auto px-8 flex">
          <div className="text-center lg:text-left lg:w-1/2 rounded-lg shadow">
            <h1 className="p-3 text-xl text-blue-800 lg:text-2xl xl:text-3xl font-bold leading-none"><FontAwesomeIcon icon={faBuilding} /> Company details</h1>
            <ul className="ml-4">
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">Company name: <strong>{user.shipping.name}</strong></li>
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">Address: <br />
                <address>
                  &nbsp;{user.shipping.address.line1}<br />
                  &nbsp;{user.shipping.address.line2}<br />
                  &nbsp;{user.shipping.address.city}<br />
                  &nbsp;{user.shipping.address.country}<br />
                </address></li>
            </ul>
          </div>
        </div>
      </section>
      <section className="pt-5 md:pt-10">
        <div className="container mx-auto px-8 flex">
          <div className="text-center lg:text-left lg:w-1/2 rounded-lg shadow">
            <h1 className="p-3 text-xl text-blue-800 lg:text-2xl xl:text-3xl font-bold leading-none"><FontAwesomeIcon icon={faCreditCard} /> Subscription details</h1>
            <ul className="ml-4">
              <li className="p-3 hover:bg-yellow-200 hover:text-blue-900" title="Use this link to connect to the back office of Punch-in">
                <div className="pb-3">
                  <span className="text-blue-800"><FontAwesomeIcon icon={faGlobeAfrica} /></span>
                    &nbsp;Backoffice URL: <strong><a href={`https://${user.subdomain}-admin.punch-in.co.uk`}>{`https://${user.subdomain}-admin.punch-in.co.uk`}</a></strong>
                </div>

              </li>
              <li className="p-3 hover:bg-yellow-200 hover:text-blue-900" title="Scan the QR code from Punch-in Android app on your phone or tablet">
                <div className="pb-3">
                  <span className="text-blue-800"><FontAwesomeIcon icon={faGlobeAfrica} /></span>
                    &nbsp;QR Code to use with Punch-in on Android (go to the settings icon, enable editing details and use the <em>Scan QR</em> button):
                </div>

                <QRCode value={JSON.stringify(qrCodeData)} />
              </li>
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">
                <span className="text-blue-800">{user.subscription.status === 'active' ? (<FontAwesomeIcon icon={faToggleOn} />) : (<FontAwesomeIcon icon={faToggleOn} />)}</span>
                  &nbsp;Subscription status: <strong>{user.subscription.status}</strong>
              </li>
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">
                <span className="text-blue-800"><FontAwesomeIcon icon={faReceipt} /></span>
                &nbsp;Paid until (last day): <strong className={isOverdue ? 'text-red-700' : 'text-green-700'}>{formattedEndDate}</strong> { isOverdue && (<span className="text-red-700"> <em>(Overdue)</em></span>)}
            </li>
              <li className="p-3 hover:bg-blue-500 hover:text-blue-200">
                <span className="text-blue-800"><FontAwesomeIcon icon={faFileInvoice} /></span>
                &nbsp;Next invoice: <strong>{formattedNewBilling}</strong>
              </li>
            </ul>
            <div className="mx-5">
              <button type="button" className="bg-red-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={endSubscription}>Cancel subscription *</button>
              <p><em>* Will take effect from the end of the current billing period, i.e. after {formattedEndDate}</em></p>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Profile
